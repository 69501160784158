import React, { useEffect } from "react"
import Layout from "../../components/Layout"

import TrackheroCaseStudy from "../../components/TrackheroCaseStudy"
export default function TrackHero() {
  return (
    <>
      <Layout>
        <TrackheroCaseStudy />
      </Layout>
    </>
  )
}
